/* eslint-disable react/destructuring-assignment */
import { FormRenderProps, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import {
  composeValidators,
  minLength,
  required,
  userNameValid,
  validateGroupUsername,
  validatePhoto,
} from 'utils/validators';
import FieldLocation from 'components/common/FieldLocation';
import Input from 'components/common/Input';
import { InputDescriptionField } from 'components';
import GroupLocalityTypesField from 'components/GroupLocalityTypes';
import PrivacyDrawerField from 'containers/PrivacyDrawer';
import PermissionDrawerField from 'containers/PermissionDrawer';
import JoiningManagementDrawer from 'containers/JoiningManagementDrawer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowRight } from 'public/assets/icons';
import { debounce } from 'lodash';
import { IGroupForm } from './IGroupForm';
import Interests from './interests';
import Brands from './Brands';
import Collaborators from './Collaborators';
import Admins from './Admins';
import GroupPrivate from './GroupPrivate';
import GroupBroadcast from './GroupBroadcast';
import {
  CoverImageUploader,
  FinalFormWrapper,
  FormWrapper,
  ImageUploaderWrapper,
  ProfileImageUploader,
  SectionHeader,
  SectionWrapper,
  JoinWrapper,
  JoinWrapperLeft,
  SeparateSettingsHeader,
  JoinBlockRight,
  JoinBlock,
  JoinBlockLeft,
  JoinBlockText,
  JoinSectionWrapper,
} from './GroupForm.styles';
import GroupChat from './GroupChat';

const GroupForm = (props: IGroupForm) => {
  const { t } = useTranslation();
  const [isJoinDrawerOpen, SetIsJoinDrawerOpen] = useState<boolean>(false);

  const [joinManagement, setJoinManagement] = useState({
    title: null,
    text: null,
    joiningManagement: null,
  });

  const memoizedResponse = useRef({
    response: null,
    username: null,
    lastChecked: 0,
  });

  const formatUsername = (value) => {
    return value
      ? value
          .trim()
          .toLowerCase()
          .replace(/[^a-z0-9]/g, '') // Remove any characters that are not lowercase letters or numbers
      : '';
  };

  const initialValues: any = props?.initialValues;

  const memoizedValidateGroupUsername = useMemo(() => {
    return (value: string) => {
      if (
        memoizedResponse.current.username === value &&
        memoizedResponse.current.lastChecked + 60 * 1000 > new Date().getTime()
      ) {
        return memoizedResponse.current.response;
      }
      return validateGroupUsername(initialValues?.groupUsername)(value).then(
        (response) => {
          memoizedResponse.current = {
            response,
            username: value,
            lastChecked: new Date().getTime(),
          };
          return response;
        }
      );
    };
  }, [initialValues?.groupUsername]);

  const debounceValidateGroupUsernameRef = useRef(
    debounce(validateGroupUsername, 300)
  );

  useEffect(() => {
    debounceValidateGroupUsernameRef.current = debounce(
      memoizedValidateGroupUsername,
      300
    );
  }, [initialValues?.groupUsername, memoizedValidateGroupUsername]);

  return (
    <FinalFormWrapper
      {...props}
      render={(formRenderProps: FormRenderProps) => {
        const { handleSubmit, values, form, initialValues, errors } =
          formRenderProps;
        const { inProgress, ready, onIconClick, variant = 'create' } = props;
        return (
          <FormWrapper
            onSubmit={handleSubmit}
            onIconClick={onIconClick}
            isHeaderBorder
            headerText={
              variant === 'create'
                ? t('myGroups:createGroup')
                : t('addNewGroup:editGroup')
            }
            submitText={t('editUserProfile:save')}
            isCross
          >
            <ImageUploaderWrapper>
              <CoverImageUploader
                name="coverPhoto"
                isOnePhoto
                label="Add a photo"
                isAspect
                validate={(value) =>
                  validatePhoto(value, t('addVehicle:requiredInfo'))
                }
              />
              <ProfileImageUploader
                name="profilePhoto"
                isProfileImages
                isOnePhoto
                isAspect={false}
                validate={(value) =>
                  validatePhoto(value, t('addVehicle:requiredInfo'))
                }
              />
            </ImageUploaderWrapper>

            <SectionWrapper pt={40}>
              <SectionHeader type="h3">{t('event:requiredInfo')}</SectionHeader>
              <Input
                id="groupUsername"
                name="groupUsername"
                type="text"
                format={formatUsername}
                parse={formatUsername}
                isControlledInput
                label={t('addNewGroup:groupUsername')}
                placeholder={t('addNewGroup:groupUsername')}
                validate={composeValidators(
                  required(t('error:thisFieldIsRequired')),
                  userNameValid(t('error:vaildUsername')),
                  minLength(t('error:atLeastThree'), 3),
                  debounceValidateGroupUsernameRef.current
                )}
              />
              <Input
                id="groupFullname"
                name="groupFullname"
                type="text"
                label={t('addNewGroup:groupFullName')}
                placeholder={t('addNewGroup:groupFullName')}
                validate={composeValidators(
                  required(t('error:thisFieldIsRequired')),
                  minLength(t('error:atLeastThree'), 3)
                )}
              />
              <InputDescriptionField
                placeholder={t('common:addADescription')}
                label={t('addNewGroup:addShortBioOrDescription')}
                name="description"
                showCharactersNumber
                maxLength={500}
                height={88}
                validate={composeValidators(
                  required(t('error:thisFieldIsRequired'))
                )}
              />
              <Interests />
              <FieldLocation
                name="location"
                label={t('addNewGroup:cityOrLocation')}
                headerText={t('map:tagLocation')}
                validate={composeValidators(
                  required(t('error:thisFieldIsRequired'))
                )}
              />
              <SectionHeader fs={16} fw={500}>
                {t('addNewGroup:groupLocality')}
              </SectionHeader>
              <GroupLocalityTypesField name="locality" />
            </SectionWrapper>

            <SectionWrapper>
              <SectionHeader type="h3">
                {t('addNewGroup:optionalInfo')}
              </SectionHeader>
              <Brands />

              <Input
                id="groupWebsite"
                name="groupWebsite"
                type="text"
                label={t('addNewGroup:groupWebsite')}
                placeholder={t('addNewGroup:groupWebsite')}
              />
              <Admins />
              <Collaborators />
            </SectionWrapper>
            <JoinSectionWrapper>
              <Field name="joiningManagement">
                {() => {
                  if (joinManagement?.joiningManagement) {
                    form.change(
                      'joiningManagement',
                      joinManagement?.joiningManagement
                    );
                  }
                  return (
                    <JoinWrapper onClick={() => SetIsJoinDrawerOpen(true)}>
                      <JoinWrapperLeft>
                        <SeparateSettingsHeader type="h3" mb={8}>
                          {t('group:joiningManagement:heading')}
                        </SeparateSettingsHeader>
                        <JoinBlockRight>
                          <ArrowRight />
                        </JoinBlockRight>
                      </JoinWrapperLeft>
                      {joinManagement?.text && (
                        <JoinBlock>
                          <JoinBlockLeft>
                            <SeparateSettingsHeader type="h4" fs={15} fw={500}>
                              {joinManagement?.title && joinManagement?.title}
                            </SeparateSettingsHeader>
                            <JoinBlockText>
                              {joinManagement?.text && joinManagement?.text}
                            </JoinBlockText>
                          </JoinBlockLeft>
                        </JoinBlock>
                      )}
                    </JoinWrapper>
                  );
                }}
              </Field>
            </JoinSectionWrapper>
            <JoiningManagementDrawer
              open={isJoinDrawerOpen}
              setOpen={SetIsJoinDrawerOpen}
              setJoiningManagement={setJoinManagement}
            />
            <SectionWrapper>
              <PrivacyDrawerField
                name="privacy"
                privacy={initialValues?.privacy}
              />
              <PermissionDrawerField name="permission" />
              <GroupChat />
              <GroupPrivate privateKey={initialValues?.private} />
              <GroupBroadcast broadcast={initialValues?.broadcast} />
            </SectionWrapper>
          </FormWrapper>
        );
      }}
    />
  );
};

export default GroupForm;
