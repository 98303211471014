import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSwitchAppClubAccount from 'utils/useSwitchAppClubAccount';
import {
  getAllClubsRequest,
  postActiveClubInfoRequest,
  setActiveAccountId,
} from 'store/club/actions';
import { getClubIdFromCookies } from './storageUtils';

const ClubManager = () => {
  const dispatch = useDispatch();
  const activeAccountId = useSelector((state) => state.club.activeAccountId);
  const userDetails = useSelector((store) => store.user?.userDetails);
  const userId = userDetails?._id
  const tutorialIsActive = userDetails?.tutorialIsActive
  const userActiveClub = userDetails?.activeClub;
  const { handleClubSwitch } = useSwitchAppClubAccount();
  const lastActiveAccountId = useRef(null);

  useEffect(() => {
    if (userId) {
      dispatch(getAllClubsRequest());
    }
  }, [userId]);

  // Executes on application start
  useEffect(() => {
    if (!userId || tutorialIsActive) {
      return;
    }
    const storedAccountId = userActiveClub;
    dispatch(setActiveAccountId(storedAccountId));
    dispatch(postActiveClubInfoRequest(storedAccountId));
  }, [dispatch, userId, userActiveClub, tutorialIsActive]);

  useEffect(() => {
    if (!userId || tutorialIsActive) {
      return;
    }
    const storedActiveAccountId = getClubIdFromCookies();
    // handles switching between clubs or switching from roadstr to club mode automatically
    if (activeAccountId && storedActiveAccountId !== activeAccountId) {
      handleClubSwitch(activeAccountId);
      // handles case when we have a club selected on webapp but user switched to roadstr on mobile app or any other browser
    } else if (!userActiveClub && storedActiveAccountId) {
      handleClubSwitch('RoadStr');
      // handles case when we were in club mode but then switched to roadstr
    } else if (lastActiveAccountId.current && !activeAccountId) {
      handleClubSwitch('RoadStr');
    }
    lastActiveAccountId.current = activeAccountId;
  }, [activeAccountId, dispatch, handleClubSwitch, userId, tutorialIsActive]);
  return null;
};

export default ClubManager;
