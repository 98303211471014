import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';

import {
  openSidebarChatsList,
  openSidebarNotifications,
} from 'store/sideBarControler/actions';
import { ThemeContext } from 'theme';
import Link from 'components/common/Link';
import Button from 'components/common/Button';
import SingInForm from 'forms/SignInForm';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import auths, { clearAuthData } from 'utils/auths';
import imageResize from 'utils/imageResize';
import { routesPath } from 'utils/constants/routePath';
import Navigation from 'components/Navigation';
import { useWindowSize } from 'hooks';
import MobileLogoutHeader from 'components/MobileLogoutHeader';
import PublicHeader from 'components/PublicHeader';
import MobileLoginHeader from 'components/MobileLoginHeader';
import MobileBottomNav from 'components/MobileBottomNav';
import { ConfirmationModal } from 'components';
import { fetchApiReq } from 'store/fetchApi/actions';
import ViewProfile from 'components/ViewProfile';
import { getAllClubsRequest } from 'store/club/actions';
import { clearClubIdFromStorage } from 'utils/storageUtils';
import CreateActionsButton from './CreateActionsButton';
import ProfileActionsButton from './ProfileActionsButton';

import {
  DesktopLoginHeaderLeft,
  DesktopLoginHeaderRight,
  DesktopLogInHeaderWrapper,
  DesktopLogOutHeaderLeft,
  DesktopLogOutHeaderRight,
  DesktopLogOutHeaderWrapper,
  HeaderWrapper,
  MobileAuthHeader,
  RightBorder,
} from './Header.styles';
import Image from 'next/image';

const Header = () => {
  const [logoutLoading, setLogoutLoading] = useState(false);
  const [isConformationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const username = useSelector((store) => store.user?.userDetails?.username);
  const activeClub = useSelector((state) => state?.club?.activeClub);
  const clubLogo = activeClub?.theme?.logoMedia?.url;
  const router = useRouter();
  const { t } = useTranslation();
  const getWindowSize = useWindowSize();
  const context: any = useContext(ThemeContext);
  const fullName = context?.user?.fullName;
  const userData = context?.user;
  const userId = context?.userId;
  const vehicles = userData?.garage?.vehicles;

  const dispatch = useDispatch();

  const {
    isOpenSidebarNotifications,
    isSidebarOpen,
    isOpenSidebarChatsList,
    isOpenSidebarChatConversation,
  } = useSelector((state) => state.sideBarControler);

  const { getSideMyEventsReqStat } = useSelector((state) => state.user);

  const {
    sidebarsInfo: {
      isOpenSidebarSavedRoutes,
      isOpenSidebarWhereTo,
      isOpenSidebarPlanRoute,
      isOpenSidebarChoseStartPoint,
      isOpenSidebarChoseWayPoint,
      isOpenSidebarRouteDetail,
      isOpenSidebarChoseEndPoint,
    },
  } = useSelector((state) => state.map);

  const route = router?.route;
  const Logo = context.content.logoWithText;
  const AuthenticateLogo = context.content.smallLogo;
  const { isAuthenticate } = context;

  const windowSize = getWindowSize.width > 767;
  const identityData = useSelector((state) => state.identity);
  const { action, loading } = fetchApi();

  // Sign in action
  const onHandleSignin = async (v) => {
    try {
      const resData = await action(API_NAME.AUTH_LOGIN, {
        params: {
          nameOrEmail: v?.usernameOrEmail,
        },
        body: {
          password: v?.password,
        },
      });
      auths(resData?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onMyProfileClick = () => {
    if (router.query.id === userId) return;
    if (router.query.id !== userId) {
      router.push(`${routesPath.user_details}/${userId}`);
    }
    if (
      router.pathname !== routesPath.user_details &&
      router.query.id === userId
    ) {
      router.push(`${routesPath.user_details}/${userId}`);
    }
  };

  const logOut = async () => {
    try {
      setLogoutLoading(true);
      await clearAuthData();
      clearClubIdFromStorage();
      // if (typeof window !== 'undefined') {
      //   // localStorage.setItem('token', 'undefined');
      // }
      setIsConfirmationModalOpen(false);
      dispatch(fetchApiReq(undefined));
      router.push(`${routesPath.authentication}`);
      setLogoutLoading(false);
    } catch (error) {
      console.log(error);
      setLogoutLoading(false);
    }
  };

  const onOpenNotification = () => dispatch(openSidebarNotifications());
  const onOpenChatList = () => dispatch(openSidebarChatsList());

  const profilePhoto = context?.user?.profilePhoto?.trim();
  const profilePhotoThumb = imageResize(profilePhoto, '_thumb');

  const notAuthPath = route !== routesPath.authentication;
  const notTutorialPath = route !== routesPath.tutorial;

  return (
    <HeaderWrapper
      isAuthenticate={context?.isAuthenticate}
      isAuthPath={route === routesPath.authentication}
    >
      {notAuthPath ? (
        !isAuthenticate ? (
          windowSize ? (
            <DesktopLogOutHeaderWrapper>
              <DesktopLogOutHeaderLeft>
                <Link path="/">
                  <Logo />
                </Link>
                <Button
                  variant="primary"
                  onClick={() => router.push(routesPath.authentication)}
                >
                  {t('auth:signUpText')}
                </Button>
              </DesktopLogOutHeaderLeft>
              <DesktopLogOutHeaderRight>
                <SingInForm
                  inProgress={loading[API_NAME.AUTH_LOGIN]}
                  ready={false}
                  onSubmit={onHandleSignin}
                  onForgotPasswordClick={() =>
                    router.push(routesPath.authentication)
                  }
                  placeholderText={t('auth:usernameText')}
                  placeholderPassword={t('auth:passwordText')}
                />
              </DesktopLogOutHeaderRight>
            </DesktopLogOutHeaderWrapper>
          ) : (
            <MobileLogoutHeader />
          )
        ) : windowSize ? (
          <DesktopLogInHeaderWrapper>
            <DesktopLoginHeaderLeft>
              {clubLogo ? (
                <Image
                  src={clubLogo}
                  alt="user image"
                  width={36}
                  height={36}
                />
              ) : (
                <AuthenticateLogo />
              )}
              <RightBorder />
              <Navigation
                onOpenChatList={onOpenChatList}
                onOpenNotification={onOpenNotification}
                isOpenSidebarNotifications={isOpenSidebarNotifications}
                isOpenSidebarChatsList={isOpenSidebarChatsList}
                isOpenSidebarChatConversation={isOpenSidebarChatConversation}
              />
            </DesktopLoginHeaderLeft>
            {notTutorialPath && (
              <DesktopLoginHeaderRight>
                <CreateActionsButton disabled={false} />
                <ProfileActionsButton
                  userImage={profilePhotoThumb || profilePhoto}
                  userName={userData?.username}
                  viewProfile={
                    <ViewProfile
                      userImage={profilePhotoThumb || profilePhoto}
                      userName={userData?.username}
                      fullName={fullName}
                      onMyProfileClick={onMyProfileClick}
                    />
                  }
                  // donationMetricBanner={
                  //   <DonationMetricBanner variant="menu" />
                  // }
                  setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                />
              </DesktopLoginHeaderRight>
            )}
          </DesktopLogInHeaderWrapper>
        ) : (
          <MobileAuthHeader>
            <MobileLoginHeader
              isSidebarOpen={isSidebarOpen}
              isOpenSidebarSavedRoutes={isOpenSidebarSavedRoutes}
              isOpenSidebarWhereTo={isOpenSidebarWhereTo}
              isOpenSidebarPlanRoute={isOpenSidebarPlanRoute}
              isOpenSidebarChoseStartPoint={isOpenSidebarChoseStartPoint}
              isOpenSidebarChoseWayPoint={isOpenSidebarChoseWayPoint}
              isOpenSidebarRouteDetail={isOpenSidebarRouteDetail}
              isOpenSidebarChoseEndPoint={isOpenSidebarChoseEndPoint}
              isConformationModalOpen={isConformationModalOpen}
            />
            <MobileBottomNav
              userImage={
                clubLogo
                  ? clubLogo
                  : profilePhotoThumb || profilePhoto
              }
              onOpenNotification={onOpenNotification}
              setIsConfirmationModalOpen={setIsConfirmationModalOpen}
              isSidebarOpen={isSidebarOpen}
              isOpenSidebarSavedRoutes={isOpenSidebarSavedRoutes}
              isOpenSidebarWhereTo={isOpenSidebarWhereTo}
              isOpenSidebarPlanRoute={isOpenSidebarPlanRoute}
              isOpenSidebarChoseStartPoint={isOpenSidebarChoseStartPoint}
              isOpenSidebarChoseWayPoint={isOpenSidebarChoseWayPoint}
              isOpenSidebarRouteDetail={isOpenSidebarRouteDetail}
              isOpenSidebarChoseEndPoint={isOpenSidebarChoseEndPoint}
              isConformationModalOpen={isConformationModalOpen}
              viewProfile={
                <ViewProfile
                  userImage={profilePhotoThumb || profilePhoto}
                  userName={userData?.username}
                  fullName={fullName}
                  onMyProfileClick={onMyProfileClick}
                />
              }
              // donationMetricBanner={<DonationMetricBanner variant="menu" />}
            />
          </MobileAuthHeader>
        )
      ) : (
        <PublicHeader />
      )}
      {isConformationModalOpen && (
        <ConfirmationModal
          headerText={t('home:logOut')}
          bodyText={t('profile:areYouSureYouWantToLogOut')}
          submitText={t('settings:logOutUppercase')}
          onSubmit={logOut}
          loading={logoutLoading}
          onCancelClick={() => setIsConfirmationModalOpen(false)}
        />
      )}
    </HeaderWrapper>
  );
};

export default Header;
