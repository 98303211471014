/* eslint-disable import/no-cycle */
import { all, put, takeLatest } from 'redux-saga/effects';
import Router from 'next/router';
import cookieCutter from 'cookie-cutter';

import { routesPath } from 'utils/constants/routePath';
import { clearPostsStore } from 'store/posts/actions';
import { clearClubIdFromStorage } from 'utils/storageUtils';
import { actionTypes, logOutSuccess, logOutError } from './actions';
import { resetLoginData } from '../Login/actions';

function* workerLogOut() {
  try {
    yield put(logOutSuccess(true));
    yield put(resetLoginData());
    yield cookieCutter.set('tsec', undefined);
    clearClubIdFromStorage();
    yield localStorage.setItem('token', 'undefined');
    yield localStorage.setItem('user_id', 'undefined');
    yield localStorage.setItem('user_data', 'undefined');
    yield Router.push(`${routesPath.authentication}`);
    yield put(clearPostsStore());
  } catch (err) {
    yield put(logOutError(err));
  }
}

function* watchLogOut() {
  yield all([takeLatest(actionTypes.LOGOUT_START, workerLogOut)]);
}

export default watchLogOut;
