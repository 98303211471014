import { HYDRATE } from 'next-redux-wrapper';

import { AsyncStatus } from 'utils/types';
import { MAP_FILTER_DATA, MapSidebarTypes } from 'utils/constants';
import { actionTypes } from './actions';

const initialState = {
  sidebarsInfo: {
    isOpenSidebarSavedRoutes: false,
    isOpenSidebarWhereTo: false,
    isOpenSidebarPlanRoute: false,
    isOpenSidebarChoseStartPoint: false,
    isOpenSidebarChoseEndPoint: false,
    isOpenSidebarChoseWayPoint: false,
    isOpenSidebarRouteDetail: false,
    visibleSidebar: null,
  },

  routeDetails: null,
  savedRoutes: null,

  routeForEditData: null,

  selectedWayPointId: 0,

  directionsForDriving: null,

  routeDetailsId: null,
  mapFilter: MAP_FILTER_DATA.map((el) => el?.label),

  createRouteReqStat: AsyncStatus.NONE,
  updateRouteReqStat: AsyncStatus.NONE,
  deleteRouteReqStat: AsyncStatus.NONE,
  getRouteDetailsByIdReqStat: AsyncStatus.NONE,
  addRouteToFavoritesReqStat: AsyncStatus.NONE,
  deleteRouteFromFavoritesReqStat: AsyncStatus.NONE,
  getSavedRoutesReqStat: AsyncStatus.NONE,
  getDirectionsForDrivingReqStat: AsyncStatus.NONE,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    // SIDEBAR SAVED ROUTES
    case actionTypes.OPEN_SIDEBAR_SAVED_ROUTES:
      return {
        ...state,
        sidebarsInfo: {
          ...state.sidebarsInfo,
          visibleSidebar: MapSidebarTypes.SAVED_ROUTES,
          isOpenSidebarSavedRoutes: true,
        },
      };

    case actionTypes.CLOSE_SIDEBAR_SAVED_ROUTES:
      return {
        ...state,
        sidebarsInfo: {
          ...state.sidebarsInfo,
          isOpenSidebarSavedRoutes: false,
        },
      };

    // SIDEBAR WHERE TO
    case actionTypes.OPEN_SIDEBAR_WHERE_TO:
      return {
        ...state,
        sidebarsInfo: {
          ...state.sidebarsInfo,
          visibleSidebar: MapSidebarTypes.WHERE_TO,
          isOpenSidebarWhereTo: true,
        },
      };

    case actionTypes.CLOSE_SIDEBAR_WHERE_TO:
      return {
        ...state,
        sidebarsInfo: {
          ...state.sidebarsInfo,
          isOpenSidebarWhereTo: false,
        },
      };

    // SIDEBAR PLAN ROUTE
    case actionTypes.OPEN_SIDEBAR_PLAN_ROUTE:
      return {
        ...state,
        routeForEditData: action.data || state.routeForEditData,
        sidebarsInfo: {
          ...state.sidebarsInfo,
          visibleSidebar: MapSidebarTypes.PLAN_ROUTE,
          isOpenSidebarPlanRoute: true,
        },
      };

    case actionTypes.CLOSE_SIDEBAR_PLAN_ROUTE:
      return {
        ...state,
        sidebarsInfo: {
          ...state.sidebarsInfo,
          isOpenSidebarPlanRoute: false,
        },
      };

    // SIDEBAR CHOSE START POINT
    case actionTypes.OPEN_SIDEBAR_CHOSE_START_POINT:
      return {
        ...state,
        sidebarsInfo: {
          ...state.sidebarsInfo,
          visibleSidebar: MapSidebarTypes.CHOSE_START_POINT,
          isOpenSidebarChoseStartPoint: true,
        },
      };

    case actionTypes.CLOSE_SIDEBAR_CHOSE_START_POINT:
      return {
        ...state,
        sidebarsInfo: {
          ...state.sidebarsInfo,
          isOpenSidebarChoseStartPoint: false,
        },
      };

    // SIDEBAR CHOSE END POINT
    case actionTypes.OPEN_SIDEBAR_CHOSE_END_POINT:
      return {
        ...state,
        sidebarsInfo: {
          ...state.sidebarsInfo,
          visibleSidebar: MapSidebarTypes.CHOSE_END_POINT,
          isOpenSidebarChoseEndPoint: true,
        },
      };

    case actionTypes.CLOSE_SIDEBAR_CHOSE_END_POINT:
      return {
        ...state,
        sidebarsInfo: {
          ...state.sidebarsInfo,
          isOpenSidebarChoseEndPoint: false,
        },
      };

    // SIDEBAR CHOSE WAY POINT
    case actionTypes.OPEN_SIDEBAR_CHOSE_WAY_POINT:
      return {
        ...state,
        sidebarsInfo: {
          ...state.sidebarsInfo,
          visibleSidebar: MapSidebarTypes.CHOSE_WAY_POINT,
          isOpenSidebarChoseWayPoint: true,
        },
      };

    case actionTypes.CLOSE_SIDEBAR_CHOSE_WAY_POINT:
      return {
        ...state,
        sidebarsInfo: {
          ...state.sidebarsInfo,
          isOpenSidebarChoseWayPoint: false,
        },
      };

    // SIDEBAR ROUTE DETAILS
    case actionTypes.OPEN_SIDEBAR_ROUTE_DETAIL:
      return {
        ...state,
        routeDetailsId: action.data,
        sidebarsInfo: {
          ...state.sidebarsInfo,
          visibleSidebar: MapSidebarTypes.ROUTE_DETAILS,
          isOpenSidebarRouteDetail: true,
        },
      };

    case actionTypes.CLOSE_SIDEBAR_ROUTE_DETAIL:
      return {
        ...state,
        sidebarsInfo: {
          ...state.sidebarsInfo,
          isOpenSidebarRouteDetail: false,
        },
      };

    // CLEAR MAP SIDEBAR STORE
    case actionTypes.CLEAR_MAP_SIDEBAR_STORE:
      return { ...initialState };

    // CREATE ROUTE
    case actionTypes.CREATE_ROUTE_START:
      return { ...state, createRouteReqStat: AsyncStatus.LOADING };

    case actionTypes.CREATE_ROUTE_SUCCESS:
      return { ...state, createRouteReqStat: AsyncStatus.SUCCESS };

    case actionTypes.CREATE_ROUTE_ERROR:
      return { ...state, createRouteReqStat: AsyncStatus.ERROR };

    // UPDATE ROUTE
    case actionTypes.UPDATE_ROUTE_START:
      return { ...state, updateRouteReqStat: AsyncStatus.LOADING };

    case actionTypes.UPDATE_ROUTE_SUCCESS:
      return { ...state, updateRouteReqStat: AsyncStatus.SUCCESS };

    case actionTypes.UPDATE_ROUTE_ERROR:
      return { ...state, updateRouteReqStat: AsyncStatus.ERROR };

    // DELETE ROUTE
    case actionTypes.DELETE_ROUTE_START:
      return { ...state, deleteRouteReqStat: AsyncStatus.LOADING };

    case actionTypes.DELETE_ROUTE_SUCCESS:
      return { ...state, deleteRouteReqStat: AsyncStatus.SUCCESS };

    case actionTypes.DELETE_ROUTE_ERROR:
      return { ...state, deleteRouteReqStat: AsyncStatus.ERROR };

    // GET ROUTE DETAILS BY ID
    case actionTypes.GET_ROUTE_DETAILS_BY_ID_START:
      return { ...state, getRouteDetailsByIdReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_ROUTE_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        routeDetails: action.data,
        getRouteDetailsByIdReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_ROUTE_DETAILS_BY_ID_ERROR:
      return { ...state, getRouteDetailsByIdReqStat: AsyncStatus.ERROR };

    // ADD ROUTE TO FAVORITES
    case actionTypes.ADD_ROUTE_TO_FAVORITES_START:
      return { ...state, addRouteToFavoritesReqStat: AsyncStatus.LOADING };

    case actionTypes.ADD_ROUTE_TO_FAVORITES_SUCCESS:
      return { ...state, addRouteToFavoritesReqStat: AsyncStatus.SUCCESS };

    case actionTypes.ADD_ROUTE_TO_FAVORITES_ERROR:
      return { ...state, addRouteToFavoritesReqStat: AsyncStatus.ERROR };

    // DELETE ROUTE FROM FAVORITES
    case actionTypes.DELETE_ROUTE_FROM_FAVORITES_START:
      return { ...state, deleteRouteFromFavoritesReqStat: AsyncStatus.LOADING };

    case actionTypes.DELETE_ROUTE_FROM_FAVORITES_SUCCESS:
      return { ...state, deleteRouteFromFavoritesReqStat: AsyncStatus.SUCCESS };

    case actionTypes.DELETE_ROUTE_FROM_FAVORITES_ERROR:
      return { ...state, deleteRouteFromFavoritesReqStat: AsyncStatus.ERROR };

    // GET SAVED ROUTES
    case actionTypes.GET_SAVED_ROUTES_START:
      return { ...state, getSavedRoutesReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_SAVED_ROUTES_SUCCESS:
      return {
        ...state,
        savedRoutes: action.data,
        getSavedRoutesReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_SAVED_ROUTES_ERROR:
      return { ...state, getSavedRoutesReqStat: AsyncStatus.ERROR };

    // GET DIRECTIONS FOR DRIVING
    case actionTypes.GET_DIRECTIONS_FOR_DRIVING_START:
      return { ...state, getDirectionsForDrivingReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_DIRECTIONS_FOR_DRIVING_SUCCESS:
      return {
        ...state,
        directionsForDriving: action.data,
        getDirectionsForDrivingReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_DIRECTIONS_FOR_DRIVING_ERROR:
      return { ...state, getDirectionsForDrivingReqStat: AsyncStatus.ERROR };

    // SET SELECTED WAY POINT ID
    case actionTypes.SET_SELECTED_WAY_POINT_ID:
      return { ...state, selectedWayPointId: action.data };

    // SET ROUTE FOR EDIT DATA
    case actionTypes.SET_ROUTE_FOR_EDIT_DATA:
      return { ...state, routeForEditData: action.data };

    // SET ROUTE DETAILS ID
    case actionTypes.SET_ROUTE_DETAILS_ID:
      return { ...state, routeDetailsId: action.data };
    case actionTypes.SET_MAP_FILTER:
      return { ...state, mapFilter: action.data };
    default:
      return state;
  }
};

export default reducer;
