import { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FieldElect from 'components/common/FieldElect';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import { FinishLocationIcon, StartLocationIcon } from 'pages/map/icons';
import { VerticalDashedLine } from 'pages/map/components';
import CheckBox from 'components/common/CheckBox';
import {
  CheckBoxSpan,
  RouteName,
  RoutesContentWrap,
  RouteWrap,
  WaypointName,
  WaypointWrap,
} from './CreateEventPostForm.styles';

const Routes = () => {
  const { t } = useTranslation();
  const { action, loading } = fetchApi();
  const getCollaborators = useSelector((store) => store.fetchData);

  const fetchData = useCallback(() => {
    action(API_NAME.GET_SAVED_ROUTES, {
      params: {
        offset: 0,
        limit: 20,
      },
    });
  }, []);

  useEffect(() => {
    if (
      !(getCollaborators[API_NAME.GET_SAVED_ROUTES]?.data?.result?.length > 0)
    ) {
      fetchData();
    }
  }, []);

  return (
    <FieldElect
      name="routeId"
      label={t('event:eventRoutes')}
      headerText={t('event:eventRoutes')}
      multipleSelect={false}
      render={(b, onRemove) => {
        if (b?.waypoints?.length > 0) {
          return (
            <RouteWrap>
              <RouteName>{b?.name}</RouteName>
              <WaypointWrap>
                <StartLocationIcon />
                <WaypointName>{b?.waypoints[0]?.waypointName}</WaypointName>
              </WaypointWrap>
              <VerticalDashedLine margin="0 0 0 11px" />
              <WaypointWrap>
                <FinishLocationIcon />
                <WaypointName>{b?.waypoints[1]?.waypointName}</WaypointName>
              </WaypointWrap>
            </RouteWrap>
          );
        }
        return '';
      }}
      formRender={(value, onChange) => {
        console.log('🚀 ~ file: Routes.tsx ~ line 64 ~ Routes ~ value', value);
        return (
          <>
            <RoutesContentWrap>
              {getCollaborators[API_NAME.GET_SAVED_ROUTES]?.data?.result
                ?.length > 0 &&
                getCollaborators[API_NAME.GET_SAVED_ROUTES]?.data?.result?.map(
                  (d, i) => (
                    <RouteWrap key={d?._id} onClick={() => onChange(d)}>
                      <RouteName>{d?.name}</RouteName>

                      <WaypointWrap>
                        <StartLocationIcon />

                        <WaypointName>
                          {d?.waypoints[0]?.waypointName}
                        </WaypointName>
                      </WaypointWrap>

                      <VerticalDashedLine margin="0 0 0 11px" />

                      <WaypointWrap>
                        <FinishLocationIcon />

                        <WaypointName>
                          {d?.waypoints[1]?.waypointName}
                        </WaypointName>
                      </WaypointWrap>
                      {d?._id === value?._id && (
                        <CheckBoxSpan>
                          <CheckBox
                            type="radio"
                            checked={d?._id === value?._id}
                          />
                        </CheckBoxSpan>
                      )}
                    </RouteWrap>
                  )
                )}
            </RoutesContentWrap>
          </>
        );
      }}
    />
  );
};

export default Routes;
