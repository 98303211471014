import cookieCutter from 'cookie-cutter';

const CLUB_ID_KEY = 'ROADSTR_CLUB_ID';

// Usage example:

export const setClubIdInStorage = (clubId: string) => {
  try {
    if (clubId) {
      localStorage.setItem(CLUB_ID_KEY, clubId);
      cookieCutter.set('clubId', clubId, { path: '/' });
    } else {
      clearClubIdFromStorage();
    }
  } catch (e) {
    console.error('Failed to save clubId to storage and set cookie', e);
  }
};

export const clearClubIdFromStorage = () => {
  localStorage.removeItem(CLUB_ID_KEY);
  cookieCutter.set('clubId', '', { path: '/', expires: new Date(0) });
};

export const hasValidObjectId = (id: string) => {
  return id?.length === 24 && /^[a-fA-F0-9]+$/.test(id);
};

export const getClubIdFromCookies = () => {
  if (typeof window !== 'undefined') {
    const clubId = cookieCutter.get('clubId');
    if (hasValidObjectId(clubId)) {
      return clubId;
    }
  }
  return null;
};
